
  import DateOnly from 'dateonly';

  export default {
    props: ['data', 'show-description', 'card'],
    components: {
      MImg: () => import('../MPowerImg'),
    },
    methods: {
      dateOnly(date) {
        if (!date) {
          return '';
        }
        return new DateOnly(date).toDate();
      },
    },
  };
