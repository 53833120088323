
import SpecialCard from './SpecialCard.vue';

export default {
  components: {
    SpecialCard,
  },
  props: {
    specialCardList: {
      type: Object,
      required: false,
    },
    specialList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasSpecials() {
      return this.specialList.length > 0;
    },
  },
  data() {
    return {
      query: this.$route.query.query,
    };
  },
};
